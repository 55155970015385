import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import '@vtmn/css/dist/index.css';
import '@vtmn/icons/dist/vitamix/font/vitamix.css'
import i18n from './i18n'
import crono from 'vue-crono';

createApp(App).use(i18n).use(store).use(router).use(crono).mount('#app')