import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
    
    {
            path: '/',
            name: 'process',
            component: () =>
                import( /* webpackChunkName: "Process" */ '@/views/ProcessView.vue')
    },
    {
            path: '/expedition/:ref',
            name: 'driver',
            component: () =>
                import( /* webpackChunkName: "Driver" */ '@/views/DriverView.vue')
    },
    {
            path: '/return/:ref',
            name: 'return',
            component: () =>
                import( /* webpackChunkName: "Return" */ '@/views/ReturnView.vue')
    },
    {
        path: '/reception/:ref',
        name: 'reception',
        component: () =>
            import( /* webpackChunkName: "Reception" */ '@/views/ReceptionView.vue')
    }

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (store.state.vada === null && (to.name !== 'process')) next({ name: 'process' })
    else next()
})


export default router