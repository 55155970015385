export const setVada = (state, value) => {
    state.vada = value
}

export const isAuthenticated = (state) => {
    state.authenticated = true
}

export const isAuthenticatedToFalse = (state) => {
    state.authenticated = false
}