import { createStore } from 'vuex'
import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'
import createPersistedState from "vuex-persistedstate"

export default createStore({
    plugins: [createPersistedState({
        storage: window.sessionStorage
    })],
    state: {
        authenticated: false,
        processes: [],
        fuels: [],
        showFuel: false,
        vada: '',
        plannings: [],
        isLoading: false,
        cmrs: [],
        returns: [],
        returnsOpened:[],
        role:'',
        receptions:[]
  
    },
    getters,
    actions,
    mutations,
    modules: {},
})