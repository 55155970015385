export default {
  "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immatriculation Camion"])},
  "fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un carburant"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courrier électronique"])},
  "planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un horaire"])},
  "expedition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédition"])},
  "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "return?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contient des retours?"])},
  "acceptTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je connais et j'accepte les conditions"])},
  "securityProtocol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le protocole de sécurité"])},
  "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politique de confidentialité"])},
  "andThe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et la"])},
  "gasoil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diesel"])},
  "gasoline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essence"])},
  "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaz"])},
  "electric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Électrique"])},
  "trailerRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immatriculation Remorque"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
  "editDriver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le chauffeur"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "nif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte d'identité"])},
  "cmrNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número CMR"])},
  "subCarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subcontracted carrier"])},
  "reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réception"])},
  "carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporteur"])},
  "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origine"])},
  "trailerEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(S'il n'y en a pas, laisser en blanc)"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer d'Entrepôt"])}
}